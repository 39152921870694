<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            height="string"
            v-loading="loading">
            <el-table-column align="left" type="index" width="50" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              width="100"
              label="姓名"></el-table-column>
            <el-table-column prop="department" align="center" label="部门"></el-table-column>
            <el-table-column prop="post" align="center" label="岗位"></el-table-column>
            <el-table-column
              prop="entryDate"
              align="center"
              sortable
              min-width="120"
              label="合同开始日期">
              <template slot-scope="scope">
                {{ scope.row.entryDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="positiveDate"
              align="center"
              sortable
              min-width="120"
              label="合同结束日期">
              <template slot-scope="scope">
                {{ scope.row.positiveDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_RESET_PASSWORD_Bgc"
                  @click.stop="add(scope.row)"
                  >新增合同</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Miantan_Bgc"
                  @click="edit(scope.row)"
                  >查看历史合同</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit width="600px" :options="form" :isShow.sync="showEditDialog" v-show="showEditDialog">
    </Edit>
    <Add width="600px" :options="add_form" :isShow.sync="showAddDialog" v-show="showAddDialog">
    </Add>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Add: () => import('./Add.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      form: {},
      add_form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showAddDialog: false,
    }
  },
  provide() {
    return {
      re: this.re,
      add_re: this.add_re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    add(row) {
      row.userinfoId = row.staffId
      this.add_form = row
      this.showAddDialog = true
    },
    edit(row) {
      row.userinfoId = row.staffId
      this.form = row
      this.showEditDialog = true
    },
    getData() {
      this.loading = true
      this.$api.user
        .getLaborContractUserInfo(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    re() {
      this.showEditDialog = false
      this.form = {}
      this.add_form = {}
      this.getData()
    },
    add_re() {
      this.showAddDialog = false
      this.getData()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
